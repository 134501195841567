<template>
  <div class="login-wrapper">
    <div class="top">
      <div class="header">
        <a href="/">
          <!-- <img src="~@/assets/logo.png" class="logo" alt="logo"> -->
          <span class="title">灵活用工平台</span>
        </a>
      </div>
    </div>
    <a-form class="user-layout-login" :form="form" @submit="handleSubmit">
      <a-form-item>
        <a-input
          size="large"
          type="text"
          placeholder="请输入登录账号"
          v-decorator="['username', {rules: [{required: true, message: '请输入登录账号'}]}]"
        >
          <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }"/>
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input-password
          size="large"
          placeholder="请输入登录密码"
          v-decorator="['password', {rules: [{required: true, message: '请输入登录密码'}]}]"
        >
          <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }"/>
        </a-input-password>
      </a-form-item>
      <a-form-item>
        <a-input
          size="large"
          type="text"
          placeholder="请输入验证码"
          v-decorator="['code', {rules: [{required: true, message: '请输入验证码'}]}]"
        >
        <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }"/>
        <img slot="addonAfter" :src="codeUrl" alt="code" @click="resetCode" />
        </a-input>
      </a-form-item>
      <a-form-item style="margin-top:24px">
        <a-button
          size="large"
          type="primary"
          htmlType="submit"
          class="login-button"
          :loading="state.loginBtn"
          :disabled="state.loginBtn"
        >登录</a-button>
      </a-form-item>
      <div class="user-login-other">
        没有账号？<router-link class="register" :to="{ name: 'register' }">立即注册</router-link>
      </div>
    </a-form>
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import { login } from '@/utils/api'

export default {
  name: 'Login',
  data () {
    return {
      form: this.$form.createForm(this),
      state: {
        loginBtn: false
      },
      codeUrl: '/admin/code',
    }
  },
  methods: {
    ...mapActions(['Login']),
    resetCode() {
      this.codeUrl = `admin/code?${Math.random()}`
    },
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.state.loginBtn = true
          login(values).then(res => {
            this.state.loginBtn = false
            if (res.error == 0) {
              this.Login(res.info)
              if (res.info.role_id == 4) {
                this.$router.push({path: '/h5'})
              } else {
                this.$router.push({path: '/index/home'})
              }
            } else {
              this.$message.error(res.info)
            }
          }).catch(() => {
            this.state.loginBtn = false
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .login-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .top {
      text-align: center;
      .header {
        height: 44px;
        line-height: 44px;
        .logo {
          height: 44px;
          vertical-align: top;
          margin-right: 16px;
          border-style: none;
        }
        .title {
          font-size: 33px;
          color: rgba(0, 0, 0, .85);
          font-weight: bold;
        }
      }
    }
  }
  .user-layout-login {
    width: 368px;
    margin-top: 40px;
    .login-button {
      width: 100%;
    }
    .user-login-other {
      text-align: center;
      margin-top: 24px;
      line-height: 22px;
    }
  }
</style>
